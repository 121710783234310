import logo from "./logo.svg";
import "./App.css";
import React from "react";
// import HomePage from "./HomePage";
import HomePage from "./Homepage";

function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
