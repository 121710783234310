import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import fullLogo from "./Images/metallcont_logo_white_no_line.png";
import saldimiImage from "./saldimi.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick CSS
import "slick-carousel/slick/slick-theme.css"; // Import slick theme CSS
import "./customCarouselStyles.css";

// Importing images for the carousel
import image1 from "./Images/image1.jpg";
import image2 from "./Images/image2.jpg";
import image3 from "./Images/image3.jpg";
import image4 from "./Images/image4.jpg";
import image5 from "./Images/image5.jpg";
import image6 from "./Images/image6.jpg";
import image7 from "./Images/image7.jpg";
import image8 from "./Images/image8.jpg";
import image9 from "./Images/image9.jpg";
import image10 from "./Images/image10.jpg";
import image11 from "./Images/image11.jpg";
import image12 from "./Images/image12.jpg";
import image13 from "./Images/image13.jpg";

const HomePage = () => {
  // Array of image paths
  const imagePaths = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
  ];

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      {/* Navbar */}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: 1, borderColor: "divider", bgcolor: "#333" }}
      >
        <Toolbar>
          <Container
            maxWidth="lg"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box
              component="img"
              src={fullLogo}
              alt="MetallCont Logo"
              sx={{
                width: "100%",
                maxWidth: "150px", // Adjust as needed for your navbar
                height: "auto",
              }}
            />

            <Stack direction="row" spacing={2}>
              <Button sx={{ color: "white" }}>Home</Button>
              <Button sx={{ color: "white" }}>Product</Button>
              <Button sx={{ color: "white" }}>Pricing</Button>
              <Button sx={{ color: "white" }}>Contact</Button>
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>

      {/* Main Content and Image */}
      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={4}>
          <Box flex="1">
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontWeight: 600, color: "#333" }}
            >
              Metallcont GmbH - Ihr regionaler Fachmann rund um
              Schlosserarbeiten
            </Typography>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 500, color: "#0073e6" }}
              >
                Höchste Qualität
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ lineHeight: 1.6, color: "#555", ml: 2 }}
              >
                - Edelstahl, Stahl und Aluminium von bester Qualität <br />
                - Neueste Fronius Schweissgeräte <br />- Präzise Qualität
                einfacher sowie komplexer und Baugruppen
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 500, color: "#0073e6" }}
              >
                Schnell und Zuverlässig
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ lineHeight: 1.6, color: "#555", ml: 2 }}
              >
                - Einfache, unkomplizierte Lösung <br />
                - Spontan <br />
                - Gut organisiert nach Plan <br />- Disziplinierte
                Arbeitsstruktur
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 500, color: "#0073e6" }}
              >
                Über 20 Jahre Erfahrung
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ lineHeight: 1.6, color: "#555", ml: 2 }}
              >
                - Diplomierter Schweissfachmann <br />
                - MAG, MIG, WIG <br />- Breit aufgestelltes Fertigungsspektrum
                die keine Wünsche offenlagen
              </Typography>
            </Box>

            <Stack direction="row" spacing={2} mt={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{ fontWeight: 600 }}
              >
                Contact Us
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ fontWeight: 600 }}
              >
                Learn More
              </Button>
            </Stack>
          </Box>

          <Box flex="1">
            <Box
              component="img"
              src={saldimiImage}
              alt="Saldimi"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Box>
        </Box>
      </Container>

      {/* Carousel Section */}
      <Container maxWidth="lg" sx={{ mt: 4, pb: 4 }}>
        <Slider className="slider" {...settings}>
          {" "}
          {/* Ensure only one Slider instance */}
          {imagePaths.map((path, index) => (
            <Box key={index} sx={{ padding: 2 }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={path}
                  alt={`Slide ${index + 1}`}
                  style={{
                    height: "600px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </div>
            </Box>
          ))}
        </Slider>
      </Container>

      {/* Footer */}
      <Box component="footer" sx={{ bgcolor: "#333", py: 6 }}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={4}
          >
            <Box>
              <Box
                component="img"
                src={fullLogo}
                alt="MetallCont Logo"
                sx={{ width: "100%", maxWidth: "200px", mb: 2 }}
              />
              <Typography variant="subtitle1" color="white">
                Ihre Schweiss Spezialist
              </Typography>
              <Typography variant="subtitle1" color="white">
                Zurich
              </Typography>
              <Typography variant="subtitle1" color="white">
                <strong>Schweiz</strong>
              </Typography>
              <Typography variant="subtitle1" color="white">
                <strong>Phone:</strong> +41 76 505 67 80
              </Typography>
              <Typography variant="subtitle1" color="white">
                <strong>Email:</strong> metallcont.zh@gmail.com
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <a
                href="https://www.linkedin.com/in/%C3%B6mer-cemali-4b6a21127/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<LinkedInIcon sx={{ fontSize: 30 }} />}
                  sx={{
                    bgcolor: "#0073b1",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 16,
                    textTransform: "none",
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#005582",
                    },
                  }}
                >
                  LinkedIn
                </Button>
              </a>
            </Box>
          </Box>

          <Box mt={4} textAlign="center">
            <Typography variant="body2" color="white">
              © - MetallCont
            </Typography>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default HomePage;
